import React, { useState, useEffect, useRef } from 'react';
import { API_ENDPOINTS } from '../../connectapi';
import axios from 'axios';
import './addproductform.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const CreateProductForm = ({ onProductCreated }) => {
    const [collections, setCollections] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [styles, setStyles] = useState([]);
    const [formData, setFormData] = useState({
        pro_style: '',
        pro_name: '',
        pro_detail: '',
        procollection_id: '',
    });
    const [videoFile, setVideoFile] = useState(null); // State to manage the video file
    const [videoPreview, setVideoPreview] = useState(null); // State to manage the video preview URL

    const quillRef = useRef(null); // Create a ref for ReactQuill

    const categories = [
        { id: 1, name: 'Top', icon: '👕' },
        { id: 2, name: 'Bottom', icon: '👖' },
        { id: 3, name: 'Hat', icon: '🧢' },
        { id: 4, name: 'Accessories', icon: '🎒' },
        { id: 5, name: 'Shoes', icon: '👟' },
    ];

    const [addingNewStyle, setAddingNewStyle] = useState(false);
    const [newStyle, setNewStyle] = useState('');

    useEffect(() => {
        if (selectedCategory) {
            console.log('Fetching styles for category:', selectedCategory.id);
            axios.get(API_ENDPOINTS.getStylesByCategory(selectedCategory.id))
                .then(response => setStyles(response.data))
                .catch(error => console.error('Error fetching styles:', error));
        }
        fetchCollections();
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setStyles([]); // Reset styles when a new category is selected
    };

    const handleChange = (e, fieldName = null) => {
        if (fieldName) {
            // For ReactQuill or other cases where the value is passed directly
            setFormData((prevData) => ({
                ...prevData,
                [fieldName]: e, // e is the value for ReactQuill
            }));
        } else {
            // For standard inputs
            const { name, value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInMB = 10; // Maximum file size in MB
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert to bytes

        if (file && file.size > maxSizeInBytes) {
            alert(`The file is too large. Please select a video smaller than ${maxSizeInMB}MB.`);
            setVideoFile(null);
            setVideoPreview(null);
            return;
        }

        if (file) {
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file));
        }
    };

    const handleNewStyleSubmit = async () => {
        if (newStyle.trim()) {
            if (!selectedCategory || !selectedCategory.id) {
                console.error('Selected category is not defined');
                alert('Please select a category');
                return;
            }

            try {
                console.log("Adding new style with proty_id:", selectedCategory.id); // Debugging
                const response = await axios.post(API_ENDPOINTS.addStyle, {
                    prosty_name: newStyle,
                    proty_id: selectedCategory.id,
                });

                console.log('New style added:', response.data); // Log the response from the server

                setStyles(prevStyles => [...prevStyles, response.data]);
                setFormData(prevData => ({ ...prevData, pro_style: response.data.prosty_id }));
                setAddingNewStyle(false);
                setNewStyle('');
            } catch (error) {
                console.error('Error adding new style:', error.response ? error.response.data : error.message);
                alert('Error adding new style');
            }
        }
    };
    const handleNewStyleCancel = () => {
        setAddingNewStyle(false);
        setNewStyle('');
    };

    const fetchCollections = async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.getCollections);
            setCollections(response.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };
    const handleSelectChange = (event) => {
        const collectionId = event.target.value;
        // Populate form with selected collection data
        const selected = collections.find(c => c.procollection_id === parseInt(collectionId));
        setFormData((prevData) => ({
            ...prevData,
            procollection_id: collectionId
        }));
        console.log('Selected collection:', collectionId);
    };
    const handleSubmit = async (e) => {
        console.log('Form data:', formData);
        e.preventDefault();
        setIsLoading(true);

        // Ensure procollection_id is not 'undefined'
        const collectionId = formData.procollection_id;
        console.log('what it sent:', formData.procollection_id);
        if (collectionId === 'undefined' || collectionId === undefined) {
            alert('Invalid collection selected. Please select a valid collection.');
            setIsLoading(false);
            return;
        }

        const uploadData = new FormData();
        uploadData.append('pro_name', formData.pro_name);
        uploadData.append('pro_detail', formData.pro_detail);
        uploadData.append('pro_style', formData.pro_style);
        uploadData.append('proty_id', selectedCategory?.id);
        uploadData.append('procollection_id', formData.procollection_id);
        if (videoFile) uploadData.append('video', videoFile);

        try {
            const response = await axios.post(API_ENDPOINTS.addProduct, uploadData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                alert('Product created successfully!');
                onProductCreated(response.data.product.pro_id);
            } else {

                alert('Error creating product');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating product');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div className="create-product-form">
            {isLoading && (
                <div className="loading-spinner">
                    {/* This can be a simple CSS spinner or a loading GIF */}
                    <div className="spinner"></div>
                </div>
            )}
            {!selectedCategory ? (
                <>
                    <p className="advice-text">Please select a product category to begin:</p>
                    <div className="category-buttons">
                        {categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategorySelect(category)}
                                className="category-button"
                            >
                                <span className="category-icon">{category.icon}</span>
                                {category.name}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <div className="product-form">
                    <div className="product-form-header">
                        <h2>เพิ่มสิ้นค้าประเภท {selectedCategory.name}</h2>
                        <button onClick={() => setSelectedCategory(null)} className="back-button">
                            เลือกใหม่
                        </button>
                    </div>
                    <div>
                        <label>Select Collection: </label>
                        <select name="procollection_id"
                            value={formData.procollection_id}
                            onChange={handleSelectChange}
                            required>
                            <option value="">เลือกคอลเล็กชั่น : </option>
                            {collections.map(collection => (
                                <option key={collection.procollection_id} 
                                value={collection.procollection_id}>
                                    {collection.procollection_name}
                                </option>
                            ))}

                        </select>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <label>
                            Product Style:
                            {!addingNewStyle ? (
                                <select
                                    name="pro_style"
                                    value={formData.pro_style}
                                    onChange={(e) => {
                                        if (e.target.value === 'add_new') {
                                            setAddingNewStyle(true);
                                        } else {
                                            handleChange(e);
                                        }
                                    }}
                                    required
                                >
                                    <option value="">Select Style</option>
                                    {styles.map((style) => (
                                        <option key={style.prosty_id} value={style.prosty_id}>
                                            {style.prosty_name}
                                        </option>
                                    ))}
                                    <option value="add_new">+ Add new Style</option>
                                </select>
                            ) : (
                                <div className="new-style-input">
                                    <input
                                        type="text"
                                        value={newStyle}
                                        onChange={(e) => setNewStyle(e.target.value)}
                                        placeholder="Enter new style"
                                        required
                                    />
                                    <button type="button" onClick={handleNewStyleSubmit}>✔️</button>
                                    <button type="button" onClick={handleNewStyleCancel}>❌</button>
                                </div>
                            )}
                        </label>
                        <label>
                            Product Name:
                            <input type="text" name="pro_name" value={formData.pro_name} onChange={handleChange} required />
                        </label>
                        <label>
                            Product Detail:
                            <ReactQuill
                                ref={quillRef} // Attach the ref here
                                theme="snow"
                                name="pro_detail"
                                value={formData.pro_detail}
                                onChange={(content) => handleChange(content, 'pro_detail')}
                                required
                            />
                        </label>
                        <label>
                            Upload Video:
                            <input type="file" accept="video/*" onChange={handleVideoChange} />
                            {videoPreview && (
                                <video width="400" controls>
                                    <source src={videoPreview} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                </video>
                            )}
                        </label>
                        <center>
                            <button type="submit">Add Product</button>
                        </center>
                    </form>
                </div>
            )}
        </div>
    );
}
export default CreateProductForm;
