import React, { useState, useEffect } from 'react';
import './randomproducts.css';
import ProductCard from './product_card';
import axios from 'axios';
import { API_ENDPOINTS } from '../../connectapi';
import { Link } from 'react-router-dom';

const RandomProduct = () => {
    const [products, setProducts] = useState([]);
    const [randomProduct, setRandomProduct] = useState(null);
    const [isShuffling, setIsShuffling] = useState(false);

    // Fetch all products initially
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.getProducts);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, []);

    // Shuffle function to randomly pick one product
    const getRandomProduct = () => {
        const randomIndex = Math.floor(Math.random() * products.length);
        return products[randomIndex];
    };

    // Handle button click to start shuffle animation and show one random product
    const handleShuffle = () => {
        setIsShuffling(true);

        // Shuffle the cards fast for 3 seconds
        const shuffleInterval = setInterval(() => {
            setRandomProduct(getRandomProduct());
        }, 100); // Switch product every 100ms for the shuffle effect

        // Stop shuffle after 3 seconds
        setTimeout(() => {
            clearInterval(shuffleInterval);
            setIsShuffling(false);
        }, 3000); // 3 seconds shuffle duration
    };

    return (
        <div className="random-product-section">
            <div className="random-product-container">
                <button className="shuffle-button" onClick={handleShuffle} disabled={isShuffling}>
                    {isShuffling ? 'กำลังสุ่ม...' : 'สุ่มเลย'}
                </button>

                {randomProduct && (
                    <div className={`product-card-container ${isShuffling ? 'shuffling' : ''}`}>
                        <Link to={`/productDetail/${randomProduct.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ProductCard
                            id={randomProduct.id}
                            images={randomProduct.variations.flatMap(v => v.images)}
                            name={randomProduct.name}
                            price={randomProduct.variations[0]?.price || 0}
                            colors={[...new Set(randomProduct.variations.map(v => v.color))]}
                            loading={isShuffling}
                        />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RandomProduct;