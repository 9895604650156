import React, { useState, useEffect } from 'react';
import './productmange.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../../connectapi';

const ProductManagement = ({ setCurrentView, setCurrentProductId }) => {
  const [products, setProducts] = useState([]);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentVariationId, setCurrentVariationId] = useState(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axios.get(API_ENDPOINTS.getproductsvar);
        console.log('Fetched Products Data:', data);  // Log data to verify structure

        // Directly set the products state with the data received
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);


  const handleExpand = (productId, event) => {
    if (event.target.tagName !== 'BUTTON') {
      if (expandedProductId === productId) {
        // Start closing animation
        const element = document.querySelector(`.variation-row.open`);
        if (element) {
          element.classList.add('closing');
          setTimeout(() => {
            setExpandedProductId(null); // Collapse after animation ends
            element.classList.remove('closing'); // Cleanup
          }, 300); // Match the transition duration
        }
      } else {
        setExpandedProductId(productId);
      }
    }
  };

  // Function to determine text color based on background color
  const getTextColorBasedOnBackground = (hexColor) => {
    if (!hexColor) {
      return 'black'; // Default to black if hexColor is undefined
    }

    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 128 ? 'black' : 'white';
  };

  const filteredProducts = products.filter(product =>
    product.pro_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddVariaotinClick = (productId) => {
    console.log("Selected product ID:", productId); // Debugging - Check if the correct ID is logged
    setCurrentView('createProductVariation'); // Switch view
    setCurrentProductId(productId); // Set the current product ID
  };

  const handleEditProductClick = (productId) => {
    setCurrentView('editProduct');
    setCurrentProductId(productId);
  };
  
  const handleEditVariationClick = (variationId) => {
    setCurrentView('editVariation');
    setCurrentVariationId(variationId);
  };

  return (
    <div className="product-management">
      <header className="header">
        <h2>Products</h2>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
        />
      </header>
      <table className="product-table">
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>ชื่อสินค้า</th>
            <th>ประเภท</th>
            <th>สไตล์</th>
            <th>เวลาอัปเดต</th>
            <th>การจัดการ</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product, index) => (
            <React.Fragment key={product.pro_ID}>
              <tr onClick={(event) => handleExpand(product.pro_ID, event)}>
                <td>{index + 1}</td>
                <td>{product.pro_name}</td>
                <td>{product.proty_name}</td>
                <td>{product.prosty_name}</td>
                <td>{product.time_stemp ? product.time_stemp : 'No Data'}</td>
                <td>
                  <button
                    className="addmorebutton"
                    onClick={() => handleAddVariaotinClick(product.pro_ID)}
                  >
                    ➡️เพิ่มรูปแบบ
                  </button>
                  <button
                    className="addmorebutton"
                    onClick={() => handleEditProductClick(product.pro_ID)}
                  >
                    🔧แก้ไข
                  </button>
                  <button className="delbutton">❌ลบ</button>
                </td>
              </tr>
              {expandedProductId === product.pro_ID && (
                <tr className="variation-row open">
                  <td colSpan="6">
                    <table className="variation-table">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ขนาด</th>
                          <th>สี</th>
                          <th>ราคาสินค้า</th>
                          <th>Image</th> {/* Singular since we're showing only one */}
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.variations && product.variations.length > 0 ? (
                          product.variations.map((variation, varIndex) => (
                            <tr key={variation.provariation_id}>
                              <td>{varIndex + 1}</td>
                              <td>{variation.prosize_name}</td>
                              <td
                                style={{
                                  backgroundColor: variation.procol_hexcode,
                                  color: getTextColorBasedOnBackground(variation.procol_hexcode),
                                  textAlign: 'center'
                                }}
                              >
                                {variation.procol_name}
                              </td>
                              <td>{variation.provariation_price}</td>
                              <td>
                                <div className="image-container">
                                  {variation.images && variation.images.length > 0 ? (
                                    <img
                                      src={`${API_ENDPOINTS.baseurl}/${variation.images[0].proimg_url}`}
                                      alt={`Variation ${varIndex + 1} - ${variation.procol_name} ${variation.prosize_name}`}
                                      className="product-image"
                                    />

                                  ) : (
                                    <span>No images</span>
                                  )}
                                </div>
                              </td>
                              <td>
                                <button
                                  className="addmorebutton"
                                  onClick={() => handleEditVariationClick(variation.provariation_id)}
                                >
                                  🔧แก้ไข
                                </button>
                                <button className="delbutton">❌ลบ</button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No variations available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default ProductManagement;
