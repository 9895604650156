import React from 'react';
import '../css/AboutUs.css';
import Gwiconjsx from '../icons/gwiconjsx';

const AboutUs = () => {
    return (
        <div>
            <div className="about-us-page">

                <section className="company-info">
                    <div className="company-text">
                        <h2><Gwiconjsx/></h2>
                        <p>แบรนด์ของเราถูกออกแบบมาเพื่อสร้างแรงบันดาลใจ และความมั่นใจให้กับผู้หญิงทุกคน ไม่ว่าจะมีความชอบด้านแฟชั่นแบบไหนก็ตาม ไม่ว่าคุณจะมองหาความสง่างาม</p>
                        <p>ต้องการสร้างลุคที่โดดเด่นด้วยแฟชั่นชั้นสูง หรือชื่นชอบความเรียบหรูดูแพงที่ไม่ซับซ้อน แบรนด์ของเราพร้อมตอบโจทย์ทุกสไตล์</p>
                        <p>หัวใจสำคัญของแบรนด์คือความเชื่อที่ว่าผู้หญิงทุกคนควรรู้สึกมั่นใจและสบายใจในสิ่งที่สวมใส่ เราเข้าใจดีว่าความมั่นใจเกิดจากการผสมผสานระหว่างสไตล์</p>
                        <p>ทุกชิ้นส่วนของผ้าถูกออกแบบอย่างพิถีพิถันเพื่อตอบสนองความต้องการที่หลากหลายของผู้หญิงสมัยใหม่ มีสไตล์ที่สามารถเปลี่ยนจากลุคสบายๆ</p>
                        <p>เป็นลุคที่โดดเด่นได้อย่างง่ายดาย ทุกชิ้นถูกออกแบบอย่างละเอียดอ่อนเพื่อตอบโจทย์ทั้งความงามและความสบาย ให้คุณได้ฉายแสงด้วยความมั่นใจในทุกสถานการณ์</p>
                        <p className='beyou-text'>"Wear Confidence, Be You"</p>
                    </div>
                    <div className="company-image">
                        <img src="https://testsever.gorgeouswomanth.com/gorgeousapi/uploads/variation_images/673321071f8ce-resize-image-65.jpg" alt="Our Company" />
                    </div>
                </section>

                {/* Video Section 
                <section className="company-video">
                    <h2>Discover Our Story</h2>
                    <div className="video-container">
                        <video src="/path-to-your-video.mp4" controls className="about-video"></video>
                    </div>
                </section>
*/}
                {/* Contact Section */}
                <section className="contact-us">
                    <h2>Contact Us</h2>
                    <p>If you have any questions, feel free to reach out to us!</p>
                    <form className="contact-form">
                        <input type="text" placeholder="Your Name" required />
                        <input type="email" placeholder="Your Email" required />
                        <textarea placeholder="Your Message" required></textarea>
                        <button type="submit">Send Message</button>
                    </form>
                </section>
            </div>
        </div>
    );
}

export default AboutUs;
