import * as React from "react";
const SVGComponent = (props) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1813.56 1163.27"
        width="1em"
        height="1em"
        {...props}
    >
        <defs>
            <style>{".cls-1 { fill: currentColor; }"}</style>
        </defs>
        <path
            className="cls-1"
            d="M311.89,682.92h8.41c2.09,0,5.22.78,8.3,7.56,2.51,5.52,2.91,14.16,1.19,25.67l-.49,3.42-33.83,211.8-69.36-273.88-.67-6.56h-11.32l-64.71,277.84-40.38-203.91h.69l-1.9-9.24c-2.3-11.2-2.11-19.55.57-24.83,2.68-5.3,5.51-7.87,8.64-7.87h8.41v-21.16H0v21.16h8.03c4.73,0,10.01,2.87,15.7,8.53,4.75,4.74,8.6,12.38,11.43,22.7l92.54,447.77h11.99l.14-6.99c.24-11.74,1.04-23.23,2.39-34.14,1.34-10.89,3.52-24.02,6.47-39.02l55.03-232.19,79.01,312.34h12.45l-.17-7.43c-.25-10.85.12-21.96,1.1-33.03.97-11.13,2.72-24.64,5.2-40.14l63.96-365.45c2.5-11.2,6.39-19.39,11.55-24.34,5.95-5.71,11.39-8.6,16.15-8.6h8.03v-21.16h-89.11v21.16Z"
        />
        <path
            className="cls-1"
            d="M665.02,683.48c-23.04-20.7-50.49-31.2-81.57-31.2s-58.53,10.5-81.57,31.2c-23.05,20.72-41.06,50.42-53.54,88.27-12.38,37.56-18.65,82.65-18.65,134s6.28,97.01,18.66,134.35c12.48,37.63,30.49,67.21,53.53,87.91,23.03,20.7,50.48,31.2,81.57,31.2s58.54-10.5,81.57-31.2c23.04-20.7,41.11-50.39,53.72-88.24,12.51-37.57,18.85-82.66,18.85-134.03s-6.34-97.03-18.86-134.38c-12.6-37.61-30.67-67.18-53.71-87.89ZM665.13,905.75c0,43.7-3.49,81.9-10.37,113.54-6.78,31.17-16.4,55.52-28.6,72.4-12.01,16.63-25.98,24.71-42.71,24.71s-30.41-8.09-42.54-24.74c-12.32-16.87-21.99-41.22-28.77-72.37-6.88-31.62-10.37-69.82-10.37-113.54s3.49-81.92,10.37-113.54c6.77-31.13,16.45-55.48,28.77-72.37,12.14-16.65,26.06-24.74,42.54-24.74s30.7,8.08,42.71,24.71c12.2,16.89,21.82,41.25,28.6,72.4,6.88,31.64,10.37,69.84,10.37,113.54Z"
        />
        <path
            className="cls-1"
            d="M1127.92,1119.46c-4.1-5.75-6.81-14.03-8.07-24.62l-32.94-442.56h-11.08l-109.46,335.76-113.01-331.73-1.37-4.03h-11.03l-32.48,445.66c-1.22,9.11-3.71,16.35-7.39,21.52-4.37,6.14-9.17,9.12-14.68,9.12h-11.06v21.16h89.11v-20.49h-10.68c-1.47,0-3.72-.62-6.29-6.01-2.34-4.9-3.47-10.1-3.47-15.88v-1.7l13.12-214.82,90.78,272.44h11.93v-7.2c0-10.52,1.14-21.19,3.38-31.72,2.32-10.93,6-24.28,10.92-39.67l62.89-194.92,13.12,215.74v1.85c0,5.83-1.09,11.07-3.34,16.01-2.38,5.22-4.68,5.89-6.42,5.89h-10.68v20.49h123.55v-21.16h-11.06c-5.25,0-9.93-2.98-14.3-9.12Z"
        />
        <path
            className="cls-1"
            d="M1446.97,1120.21c-4.08-4.4-7.83-11.48-11.15-21.04l-115.54-450.94h-11.89v7.2c0,4.24-.83,10.51-2.45,18.63-1.75,8.68-3.7,17.82-5.82,27.17-2.13,9.42-4.15,17.87-6,25.13-1.87,7.34-3.18,12.39-3.92,15.01l-.04.14-88.32,357.16c-3.22,9.81-7,17.04-11.22,21.51-5.28,5.58-10.33,8.41-15.02,8.41h-8.41v21.16h91v-21.16h-8.41c-1.9,0-3.87-1.17-6.02-3.56-2.19-2.45-3.73-6-4.7-10.86-.87-4.35-.66-9.9.62-16.51l18.78-80.52h81.6l15.37,68.82h-.1l2.11,9.41c2.5,11.15,2.36,19.56-.41,25-2.81,5.53-5.83,8.22-9.21,8.22h-8.41v21.16h130.37v-21.16h-8.03c-4.67,0-9.65-2.82-14.79-8.37ZM1330.51,974.33h-62.05l31.69-135.92,30.36,135.92Z"
        />
        <path
            className="cls-1"
            d="M1688.87,661.76v21.16h12.19c5.01,0,9.12,3,12.57,9.15,3.74,6.69,5.67,14.88,5.9,25.03v247.63l-183.83-313.13h-11.85l.8,8.2c2.98,30.64,4.49,58.76,4.49,83.57v351.13c0,9.67-1.87,17.71-5.71,24.58-3.57,6.39-7.62,9.49-12.38,9.49h-12.19v21.16h124.69v-21.16h-12.19c-4.76,0-8.81-3.11-12.38-9.49-3.84-6.87-5.71-14.9-5.71-24.58l-.05-7.2h0l-.24-241.21,184.13,313.12h11.54l-.87-8.27c-1.49-14.23-2.63-28.06-3.37-41.12-.74-13.03-1.12-27.97-1.12-44.4v-348.31c.23-10.12,2.2-18.28,6.04-24.95,3.58-6.21,7.64-9.24,12.43-9.24h11.81v-21.16h-124.69Z"
        />
        <path
            className="cls-1"
            d="M55.18,562.21c17.86,24.6,39.08,37.07,63.08,37.07,16.95,0,32.56-5.46,46.42-16.23,13.65-10.61,25.57-24.28,35.41-40.64,9.78-16.25,17.25-32.78,22.22-49.12l.42-1.38v-131.73c-.26-15.45,1.98-22.09,3.91-24.94.92-1.35,2.54-3.15,6.07-3.15h9.5v-22.28h-94.2v22.28h9.79c2.81,0,10.28,0,10.28,28.24l-.3,119.93c-2.08,15.55-5.54,28.78-10.29,39.34-4.76,10.6-10.32,18.73-16.53,24.17-6.04,5.3-12.35,8-18.76,8.01-13.03-.49-23.88-9.7-33.17-28.16-9.72-19.3-17.25-47.53-22.37-83.9-5.2-36.91-7.84-80.85-7.84-130.59,0-52.84,2.84-98.74,8.43-136.43,5.51-37.1,13.75-65.94,24.52-85.74,10.38-19.09,22.57-28.37,37.26-28.37,9.52,0,18.71,4.23,27.32,12.56,8.71,8.45,16.55,22.88,23.32,42.89,6.93,20.52,12.33,48.6,16.03,83.46l.65,6.11h12.02l-.65-144.97-1.44-2.09c-6.16-8.93-13.97-16.82-23.19-23.44-9.06-6.5-19.06-11.84-29.72-15.89-10.76-4.08-21.97-6.15-33.33-6.15-24.77,0-46.54,12.46-64.7,37.03-17.83,24.13-31.69,58.45-41.2,102C4.76,193.19,0,245.09,0,304.4s4.82,111.5,14.31,155.06c9.6,44.07,23.35,78.64,40.86,102.75Z"
        />
        <path
            className="cls-1"
            d="M308.58,562.31c18.54,24.53,40.7,36.97,65.86,36.97s47.31-12.44,65.85-36.97c18.23-24.11,32.5-58.55,42.42-102.38,9.81-43.35,14.78-95.41,14.78-154.74s-4.97-112.05-14.78-155.15c-9.92-43.55-24.19-77.86-42.42-101.98-18.55-24.53-40.71-36.97-65.85-36.97s-47.3,12.44-65.86,36.97c-18.24,24.13-32.46,58.58-42.28,102.4-9.7,43.35-14.62,95.4-14.62,154.72s4.92,112.05,14.63,155.13c9.82,43.57,24.04,77.89,42.27,102ZM374.44,58.59c12.57,0,23.19,9.19,32.47,28.09,9.75,19.86,17.44,48.54,22.86,85.23,5.49,37.18,8.28,82.03,8.28,133.28s-2.79,96.1-8.28,133.28c-5.43,36.71-13.12,65.38-22.86,85.23-9.27,18.9-19.9,28.09-32.47,28.09s-22.96-9.2-32.34-28.12c-9.84-19.85-17.58-48.51-23-85.2-5.49-37.15-8.28-81.99-8.28-133.28s2.79-96.13,8.28-133.28h0c5.42-36.67,13.16-65.34,23-85.2,9.38-18.92,19.95-28.12,32.33-28.12Z"
        />
        <path
            className="cls-1"
            d="M724.83,560.53c-3.37-3.32-6.71-8.93-9.92-16.66-3.49-8.43-7.43-19.71-11.68-33.42-2.75-9.29-5.85-20.35-9.23-32.89-3.37-12.5-6.63-25.04-9.7-37.28-3.1-12.33-5.69-22.71-7.79-31.12-2.65-10.6-3.22-13.09-3.32-13.61l-.12-.57c-3.47-13.96-7.97-28.11-13.38-42.07-3.47-8.96-7.25-16.34-11.28-22.08,8.42-5.27,15.6-14.24,21.41-26.77,7.49-16.14,13.12-35.77,16.75-58.34,3.56-22.25,5.37-44.93,5.37-67.43,0-28.42-2.09-52.73-6.21-72.24-4.17-19.75-9.88-35.98-16.97-48.23-7.18-12.39-15.52-21.55-24.81-27.24-9.18-5.61-18.71-8.45-28.32-8.45h-107.77v22.28h8.89c1.19,0,4.82,0,8.95,10.84,3.28,8.64,5.04,19.31,5.23,31.7v436.5c-.19,12.4-1.95,23.06-5.23,31.7-4.12,10.84-7.75,10.84-8.95,10.84h-8.89v22.28h100.79v-22.28h-8.6c-1.21,0-4.87,0-9.13-10.91-3.35-8.58-5.05-19.19-5.05-31.55v-206.48l3.57,1.41c2.65,1.04,5.51,5.1,8.26,11.73,3.2,7.7,6.07,19.66,8.53,35.57.62,4.91,1.65,11.62,3.04,19.94,1.39,8.38,3.06,17.85,4.98,28.16,1.89,10.24,3.84,20.86,5.83,31.88,1.99,11.04,3.97,21.56,5.87,31.27,1.92,9.86,3.73,18.12,5.34,24.48,5.16,21.18,11.39,38.35,18.51,51.01,7.3,13.01,15.86,22.36,25.42,27.79,9.32,5.3,19.82,7.99,31.2,7.99h34.36v-21.8l-4.66-.46c-4.32-.43-8.02-2.21-11.3-5.46ZM585.89,296.41V71.2c2.77-.01,5.77-.15,8.91-.4,2.96-.23,5.73-.12,8.23.35l.45.05c7.96.43,14.62,8.09,20.36,23.41,6.18,16.52,9.61,43.4,10.19,79.99.19,7.64.14,14.55-.14,20.53-.29,6.12-.64,11.57-1.02,16.18v.15c-1.55,22.28-4.24,39.84-8.01,52.21-3.65,11.99-7.73,20.61-12.15,25.61-4.22,4.79-8.35,7.12-12.63,7.12h-14.18Z"
        />
        <path
            className="cls-1"
            d="M777.88,562.21c17.86,24.6,39.08,37.07,63.08,37.07,16.95,0,32.56-5.46,46.42-16.23,13.65-10.61,25.57-24.28,35.41-40.64,9.78-16.25,17.25-32.78,22.22-49.12l.42-1.38v-131.73c-.26-15.45,1.98-22.09,3.91-24.94.92-1.35,2.54-3.15,6.07-3.15h9.5v-22.28h-94.2v22.28h9.79c2.81,0,10.28,0,10.28,28.24l-.3,119.93c-2.08,15.55-5.54,28.78-10.29,39.34-4.76,10.6-10.32,18.73-16.53,24.17-6.04,5.3-12.35,8-18.76,8.01-13.03-.49-23.88-9.7-33.17-28.16-9.72-19.3-17.25-47.53-22.37-83.9-5.2-36.91-7.84-80.85-7.84-130.59,0-52.84,2.84-98.74,8.43-136.43,5.51-37.1,13.75-65.94,24.52-85.74,10.38-19.09,22.57-28.37,37.26-28.37,9.52,0,18.71,4.23,27.32,12.56,8.71,8.45,16.55,22.88,23.32,42.89,6.93,20.52,12.33,48.6,16.03,83.46l.65,6.11h12.02l-.65-144.97-1.44-2.09c-6.16-8.93-13.97-16.82-23.19-23.44-9.06-6.5-19.06-11.84-29.72-15.89-10.76-4.08-21.97-6.15-33.33-6.15-24.77,0-46.54,12.46-64.7,37.03-17.83,24.13-31.69,58.45-41.2,102-9.4,43.06-14.16,94.97-14.16,154.28s4.82,111.5,14.31,155.06c9.6,44.07,23.35,78.64,40.87,102.76h0Z"
        />
        <path
            className="cls-1"
            d="M982.65,55.24c3.28,8.64,5.04,19.31,5.23,31.7v436.5c-.19,12.4-1.95,23.07-5.23,31.7-4.12,10.84-7.75,10.84-8.95,10.84h-8.9v22.28h164.63l15.01-175.97h-12.85l-.72,5.96c-3.12,25.66-7.36,48.11-12.59,66.72-5.09,18.11-11.23,32.1-18.26,41.59-6.69,9.03-14.2,13.42-22.95,13.42h-34.25v-207.08h34.42c5.53.46,10.12,5.69,14.03,15.96,4.36,11.48,6.58,26.49,6.58,44.62v13.5h13v-195.68h-13v12.72c0,18.15-2.22,33.32-6.6,45.07-3.91,10.51-8.49,15.85-14,16.3h-34.42V70.42h47.82c5.74,2.04,10.41,8.03,14.24,18.28,5.07,13.59,7.64,32.07,7.64,54.92v16.66h13V0l-8.16,9.55c-.75.88-2.73,2.72-7.48,5.09-3.97,2-8.38,3.78-13.08,5.29-4.51,1.45-8.83,2.19-12.84,2.19h-119.16v22.28h8.9c1.19,0,4.82,0,8.95,10.84Z"
        />
        <path
            className="cls-1"
            d="M1210.76,562.31c18.54,24.53,40.7,36.97,65.86,36.97s47.31-12.44,65.85-36.97c18.23-24.11,32.5-58.55,42.42-102.38,9.81-43.35,14.78-95.41,14.78-154.74s-4.97-112.05-14.78-155.15c-9.92-43.55-24.19-77.86-42.42-101.98-18.55-24.53-40.71-36.97-65.85-36.97s-47.3,12.44-65.86,36.97c-18.24,24.13-32.46,58.58-42.28,102.4-9.7,43.35-14.62,95.4-14.62,154.72s4.92,112.05,14.63,155.13c9.82,43.57,24.04,77.89,42.27,102ZM1276.62,58.59c12.57,0,23.19,9.19,32.47,28.09,9.75,19.86,17.44,48.54,22.86,85.23,5.49,37.18,8.28,82.03,8.28,133.28s-2.79,96.1-8.28,133.28c-5.43,36.71-13.12,65.38-22.86,85.23-9.27,18.9-19.9,28.09-32.47,28.09s-22.96-9.2-32.34-28.12c-9.84-19.85-17.58-48.51-23-85.2-5.49-37.15-8.28-81.99-8.28-133.28s2.79-96.13,8.28-133.28h0c5.42-36.67,13.16-65.34,23-85.2,9.38-18.92,19.95-28.12,32.33-28.12Z"
        />
        <path
            className="cls-1"
            d="M1582.48,571.77c13.47-17.85,23.92-43.43,31.05-76.04,7.01-32.08,10.57-70.46,10.57-114.08V76.62c0-10.17,1.32-18.46,3.92-24.64,3.18-7.57,6.03-7.57,6.96-7.57h9.79v-22.28h-72.92v22.28h9.79c.93,0,3.77,0,6.96,7.56,2.6,6.19,3.92,14.49,3.92,24.65v309.76c0,34.42-2.36,64.55-7.02,89.57-4.56,24.5-11.08,43.51-19.38,56.5-7.97,12.49-17.11,18.56-27.93,18.56s-19.44-6.39-27.2-19.54c-8.15-13.79-14.55-34.01-19.03-60.12-4.58-26.61-6.9-58.65-6.9-95.22V76.62c0-10.17,1.32-18.46,3.92-24.64,3.18-7.57,6.03-7.57,6.96-7.57h9.79v-22.28h-96.29v22.28h9.79c.93,0,3.77,0,6.96,7.56,2.6,6.19,3.92,14.49,3.92,24.65v305.81c0,43.69,3.77,81.99,11.2,113.82,7.56,32.44,19.13,57.95,34.39,75.81,15.43,18.07,34.54,27.23,56.79,27.23,19.38,0,36.2-9.26,49.98-27.52Z"
        />
        <path
            className="cls-1"
            d="M1661.38,546.49l.37.97c4.11,10.81,9.7,20.19,16.62,27.87,6.81,7.56,14.72,13.51,23.51,17.69,8.75,4.16,17.9,6.27,27.19,6.27,25.58,0,46.11-15.48,61.02-46.01,14.49-29.68,22.35-71.44,23.38-124.13.61-30.07-1.53-56.83-6.36-79.56h0c-4.88-22.96-12.25-41.9-21.89-56.31l-57.12-97.55-.1-.16c-5.86-9.44-10.75-19.78-14.53-30.73-3.62-10.45-5.36-23.11-5.17-37.58.37-19.5,3.85-36.21,10.35-49.69,6.15-12.77,13.97-18.97,23.91-18.97,11.1,0,19.79,7.08,26.55,21.63,7.09,15.28,10.69,36.07,10.69,61.81v7.2h12.76l3.95-117.02-.03-.04.48-13.21h-24.82c-1.9-.61-3.89-1.21-5.97-1.83-6.35-1.85-12.63-3.34-18.66-4.42-6.18-1.1-11.23-1.63-15.44-1.63-13.78,0-25.83,6.44-35.82,19.14-9.68,12.32-17.36,29.62-22.82,51.43-5.34,21.32-8.56,46.28-9.58,74.2-1.02,29.53.95,57.35,5.87,82.69,4.98,25.64,13.1,47.02,24.15,63.55l52.31,91.21.32.5c7.87,11.34,13.6,23.27,17.03,35.44,3.44,12.23,5.18,26.17,5.18,41.44,0,22.73-3.39,42.29-10.06,58.14-6.31,14.99-14.95,22.49-26.44,22.94-10.57.5-19.47-5.1-27.2-17.03-8.1-12.49-14.36-29.8-18.62-51.43-4.33-22.02-6.13-46.32-5.36-72.21l.22-7.51h-11.5l-1.15,4.89c-2.69,11.41-4.92,25.89-6.65,43.05-1.72,17.06-2.7,34.07-2.9,50.56-.21,16.75.53,31.32,2.19,43.31l.15,1.08Z"
        />
    </svg>
);
export default SVGComponent;
