import React, { useState, useEffect, useRef } from 'react';
import './navbar.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../css/body.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../connectapi';
import Gwicon from '../icons/gwiconjsx';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [collections, setCollections] = useState([]);
  const menuRef = useRef();
  const toggleButtonRef = useRef();
  const collectionMenuRef = useRef();
  const typeMenuRef = useRef();
  const scrollYRef = useRef(0);
  const location = useLocation();
  const navigate = useNavigate(); // Using navigate for programmatic navigation

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getCollections);
        setCollections(response.data);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchCollections();
  }, []);

  const toggleMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuOpen(!menuOpen);
    setActiveSubmenu(null); // Ensure both submenus close when main menu is closed
  };

  const toggleSubmenu = (event, submenu) => {
    event.preventDefault();
    event.stopPropagation();
    if (activeSubmenu === submenu) {
      // If the current submenu is open, close it
      setActiveSubmenu(null);
    } else {
      // Open the selected submenu and close the other submenu
      setActiveSubmenu(submenu);
    }
  };

  const handleClickInsideSubmenu = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const body = document.body;

    if (location.pathname !== '/' || window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    const handleScroll = () => {
      if (menuOpen) return;
      if (location.pathname !== '/' || window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target) &&
        !collectionMenuRef.current.contains(event.target) &&
        !typeMenuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
        setActiveSubmenu(null);
      }
    };

    if (menuOpen) {
      scrollYRef.current = window.scrollY;
      body.classList.add('no-scroll');
      body.style.top = `-${scrollYRef.current}px`;
    } else {
      body.classList.remove('no-scroll');
      body.style.top = '';
      window.scrollTo(0, scrollYRef.current);
    }

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      body.classList.remove('no-scroll');
      body.style.top = '';
    };
  }, [menuOpen, location.pathname]);

  // Function to navigate to the list page with the selected collection
  const handleCollectionClick = (collectionId) => {
    setMenuOpen(false); // Close the menu
    setActiveSubmenu(null); // Close the submenu
    navigate(`/listpageallItems?collection_id=${collectionId}`); // Navigate to the list page
  };

  const handleTypeClick = (typeId) => {
    const queryParams = new URLSearchParams(location.search);
    const collectionId = queryParams.get('collection_id');

    // Construct the new URL with the collection_id (if it exists)
    let newUrl = `/listpageallItems?type_id=${typeId}`;
    if (collectionId) {
      newUrl += `&collection_id=${collectionId}`;
    }

    setMenuOpen(false); // Close the menu
    setActiveSubmenu(null); // Close the submenu
    navigate(newUrl); // Navigate to the new URL
  };


  return (
    <header className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div>
        <div className="nav-links" onClick={toggleMenu} ref={toggleButtonRef}>
          <span>≡ MENU</span>
        </div>
        <div className="brand-container">
          <div className="brand-name">
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Gwicon className="gorgeous woman" />
            </Link>
          </div>
        </div>
        <div
          className={`sidebar ${menuOpen ? 'slide-in' : 'slide-out'}`}
          ref={menuRef}
          onClick={handleClickInsideSubmenu}
        >
          <button className="close-button" onClick={toggleMenu}>×</button>
          <ul>
            <li><Link to="/listpageallItems" style={{ textDecoration: 'none', color: 'inherit' }}>ผลิตภัณฑ์ใหม่</Link></li>
            <li onClick={(e) => toggleSubmenu(e, 'collection')} className='title'>
              Collection <a className='nextter'>&gt;</a>
            </li>
            <li onClick={(e) => toggleSubmenu(e, 'type')} className='title'>
              ประเภท <a className='nextter'>&gt;</a>
            </li>
            <Link className='nextter' to='/aboutus' style={{ textDecoration: 'none', color: 'inherit' }}>
              <li className='title'>
                เกี่ยวกับเรา
              </li>
            </Link>
          </ul>
        </div>
        {menuOpen && <div className="backdrop" onClick={toggleMenu}></div>}
        <div
          className={`collection-menu ${activeSubmenu === 'collection' ? 'slide-in' : 'slide-out'}`}
          ref={collectionMenuRef}
          onClick={handleClickInsideSubmenu}
        >
          <button className="close-button" onClick={(e) => toggleSubmenu(e, 'collection')}>×</button>
          {activeSubmenu === 'collection' && (
            <ul>
              {collections.map((collection) => (
                <li key={collection.procollection_id}>
                  <button
                    onClick={() => handleCollectionClick(collection.procollection_id)}
                    style={{ textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', padding: 0 }}
                  >
                    {collection.procollection_name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div
          className={`collection-menu ${activeSubmenu === 'type' ? 'slide-in' : 'slide-out'}`}
          ref={typeMenuRef}
          onClick={handleClickInsideSubmenu}
        >
          <button className="close-button" onClick={(e) => toggleSubmenu(e, 'type')}>×</button>
          {activeSubmenu === 'type' && (
            <ul>
              <li>
                <button onClick={() => handleTypeClick(1)} style={{ textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', padding: 0 }}>
                  เสื้อ
                </button>
              </li>
              <li>
                <button onClick={() => handleTypeClick(2)} style={{ textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', padding: 0 }}>
                  กางแกง
                </button>
              </li>
              <li>
                <button onClick={() => handleTypeClick(3)} style={{ textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', padding: 0 }}>
                  หมวก
                </button>
              </li>
              <li>
                <button onClick={() => handleTypeClick(4)} style={{ textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', padding: 0 }}>
                  กระเป๋า
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </header >
  );
}

export default Navbar;
