import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../css/productdetail_zoom.css';

const ZoomableImage = ({ image, thumbnails, onClose }) => {
  const [zoom, setZoom] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const zoomRef = useRef(null);
  
  const handleZoomChange = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - dragStart.x;
    const dy = e.clientY - dragStart.y;
    setDragStart({ x: e.clientX, y: e.clientY });
    setImagePosition((prevPosition) => ({
      x: prevPosition.x + dx,
      y: prevPosition.y + dy,
    }));
  };

  useEffect(() => {
    const handleMouseUpGlobal = () => setIsDragging(false);
    window.addEventListener('mouseup', handleMouseUpGlobal);
    return () => {
      window.removeEventListener('mouseup', handleMouseUpGlobal);
    };
  }, []);

    // Handle click outside of the zoom area
    const handleClickOutside = (event) => {
        if (zoomRef.current && !zoomRef.current.contains(event.target)) {
          onClose();
        }
      };
    
      // Handle pressing the Esc key
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      useEffect(() => {
        // Attach event listeners
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
    
        // Clean up event listeners on component unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

  return (
    <div className="zoomable-image-overlay">
      <div className="zoomable-image-container" ref={zoomRef}>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="image-area">
          <img
          draggable='false'
            ref={imageRef}
            src={image}
            alt="Zoomable"
            className="zoomable-image"
            style={{
              transform: `scale(${zoom}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          />
        </div>

        <div className="zoom-slider-container">
          <input
            type="range"
            min="1"
            max="3"
            step="0.1"
            value={zoom}
            onChange={handleZoomChange}
            className="zoom-slider"
          />
        </div>

        <div className="thumbnail-bar">
          {thumbnails.map((thumb, index) => (
            <img
              key={index}
              src={thumb}
              alt={`Thumbnail ${index + 1}`}
              className="thumbnail-image"
              onClick={() => imageRef.current.src = thumb}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ZoomableImage;
