import React, { useState, useEffect, useRef } from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

import { FaYoutube, FaFacebookF, FaTiktok, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { SiShopee } from "react-icons/si";
import Gwiconjsx from '../icons/gwiconjsx';

const Footer = () => {
  const [socialOpen, setSocialOpen] = useState(false);
  const [animating, setAnimating] = useState(false); // New state for animation

  const toggleSocial = () => {
    if (animating) return; // Prevent toggling during animation

    if (socialOpen) {
      setAnimating(true);
      document.querySelector('.social-media-icons').classList.add('closing'); // Add closing class
      setTimeout(() => {
        setSocialOpen(false); // Close after animation
        setAnimating(false);
      }, 300); // Match the duration of the closing animation
    } else {
      setSocialOpen(true); // Open immediately
    }
  };
  useEffect(() => {
    // Automatically toggle the social media icons on page load
    setSocialOpen(true); // Open the social media menu

    const timer = setTimeout(() => {
      setSocialOpen(false); // Close the social media menu after a delay
    }, 1000); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <p><Gwiconjsx/></p>
          <p>1023 อาคาร ที.พี.เอส บิลดิ้ง ชั้น 5 </p>
          <p>ถนนพัฒนาการ สวนหลวง กรุงเทพฯ 10250</p>
          <p>เบอร์ติดต่อ : 02-717-8180</p>
          <p>&copy; 2024 GORGEOUS WOMAN. All rights reserved.</p>
        </div>
        <div className="footer-column">
          <Link to='/aboutus' style={{ textDecoration: 'none', color: 'inherit' }}><h3>เกี่ยวกับเรา</h3></Link>
        </div>
        <div className="footer-column">

        </div>
        <div className="footer-column footer-signup">
          <h4></h4>
          <div className="social-icons">
            <div className={`social-media-icons ${socialOpen ? 'open' : ''}`}>
              <ul>
                <li><a href="#" className="youtube"><FaYoutube /></a></li>
                <li><a href="#" className="facebook"><FaFacebookF /></a></li>
                <li><a href="#" className="tiktok"><FaTiktok /></a></li>
                <li><a href="#" className="twitter"><FaXTwitter /></a></li>
                <li><a href="#" className="instagram"><FaInstagram /></a></li>
                <li><a href="#" className="shopee"><SiShopee /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
};

export default Footer;
