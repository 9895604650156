import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense } from 'react';
import './index.css';
import Homepage from './pages/Homepage';
import Layout from './pages/Layout';
import ListPage from './pages/Listpage';
import reportWebVitals from './reportWebVitals';
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import ProductDetail from "./pages/Productdetail";
import Slideshow from "./showcontentes/Slideshow";
import AboutUs from "./pages/AboutUs";

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>loading</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="listpageallItems" element={<ListPage />} />
            <Route path="slide" element={<Slideshow />} />
            <Route path="dashboardgwpage" element={<Dashboard />} />
            <Route path="logingwpage" element={<LoginPage />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="productDetail/:productId" element={<ProductDetail />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
