import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../connectapi';
import './collectionform.css';

const CollectionForm = ({ collectionId, onSaveSuccess }) => {
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [formData, setFormData] = useState({
        procollection_name: '',
        procollection_img: null,
        procollection_signature: null,
        procollection_designer: '',
    });
    const [previewImages, setPreviewImages] = useState({
        procollection_img: null,
    });

    useEffect(() => {
        fetchCollections();
    }, []);


    const fetchCollections = async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.getCollections);
            setCollections(response.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleSelectChange = (event) => {
        const collectionId = event.target.value;

        if (collectionId === 'new') {
            // Clear form for new collection
            setFormData({
                procollection_name: '',
                procollection_img: null,
                procollection_designer: '',
            });
            setPreviewImages({
                procollection_img: null,
            });
            setSelectedCollection(null);
        } else {
            // Populate form with selected collection data
            const selected = collections.find(c => c.procollection_id === parseInt(collectionId));
            setSelectedCollection(selected);
            setFormData({
                procollection_name: selected.procollection_name,
                procollection_img: null, // Reset to null since we'll handle it separately
                procollection_designer: selected.procollection_designer,
            });
            setPreviewImages({
                procollection_img: `${API_ENDPOINTS.getAPI}${selected.procollection_img}`,
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        const file = files[0];

        if (file) {
            setFormData(prevState => ({
                ...prevState,
                [name]: file // Only select the first file
            }));

            // Generate preview
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewImages(prevState => ({
                    ...prevState,
                    [name]: e.target.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (collectionId) {
            fetchCollectionById(collectionId);
            setSelectedCollection(collectionId); // Set the selected collection in the dropdown
        } else {
            resetForm();
        }
    }, [collectionId]);

    const fetchCollectionById = async (id) => {
        try {
            const response = await axios.get(`${API_ENDPOINTS.getCollectionById}/${id}`);
            const collection = response.data;
            setFormData({
                procollection_name: collection.procollection_name,
                procollection_img: null,
                procollection_signature: null,
                procollection_designer: collection.procollection_designer,
                procollection_bio: collection.procollection_bio
            });
            setPreviewImages({
                procollection_img: `${API_ENDPOINTS.getAPI}${collection.procollection_img}`,
                procollection_signature: `${API_ENDPOINTS.getAPI}${collection.procollection_signature}`
            });
            setSelectedCollection(collection.procollection_id); // Set the selected collection
        } catch (error) {
            console.error('Error fetching collection:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            procollection_name: '',
            procollection_img: null,
            procollection_signature: null,
            procollection_designer: '',
            procollection_bio: ''
        });
        setPreviewImages({
            procollection_img: null,
            procollection_signature: null
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formDataToSend = new FormData();
        formDataToSend.append('action', selectedCollection ? 'updateCollection' : 'addCollection');
        formDataToSend.append('procollection_name', formData.procollection_name);
        if (formData.procollection_img) {
            formDataToSend.append('procollection_img', formData.procollection_img);
        }
        formDataToSend.append('procollection_designer', formData.procollection_designer);
    
        try {
            if (selectedCollection) {
                // Edit existing collection (use POST for updating)
                formDataToSend.append('procollection_id', selectedCollection.procollection_id);
                await axios.post(API_ENDPOINTS.addCollection, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {
                // Add new collection (use POST for creating new)
                await axios.post(API_ENDPOINTS.addCollection, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }
    
            fetchCollections(); // Refresh collection list
            alert('Collection saved successfully!');
            onSaveSuccess();
        } catch (error) {
            console.error('Error saving collection:', error);
            alert('Failed to save collection.');
        }
    };

    return (
        <div className='collection-form-container'>
            <h1>Manage Collections</h1>
            <div>
                <label>Select Collection: </label>
                <select value={selectedCollection ? selectedCollection.procollection_id : 'new'} onChange={handleSelectChange}>
                    <option value="new">เพิ่มคอลเล็กชันใหม่</option>
                    {collections.map(collection => (
                        <option key={collection.procollection_id} value={collection.procollection_id}>
                            {collection.procollection_name}
                        </option>
                    ))}
                </select>
            </div>

            <form onSubmit={handleSubmit}>
                <div>
                    <label>Collection Name:</label>
                    <input
                        type="text"
                        name="procollection_name"
                        value={formData.procollection_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div>
                    <label>Collection Image:</label>
                    <input
                        type="file"
                        name="procollection_img"
                        onChange={handleFileChange}
                        required={!selectedCollection} // Required only for new collections
                    />
                    {previewImages.procollection_img && (
                        <img src={previewImages.procollection_img} alt="Collection Preview" className="image-preview" />
                    )}
                </div>

                <div>
                    <label>Designer Name:</label>
                    <input
                        type="text"
                        name="procollection_designer"
                        value={formData.procollection_designer}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <button type="submit">Save Collection</button>
            </form>
        </div>
    );
};

export default CollectionForm;
