import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CollectionList.css';
import { API_ENDPOINTS } from '../../connectapi';

const CollectionList = ({ setCurrentView, setCurrentCollectionId }) => {
    const [collections, setCollections] = useState([]);
    const [activeCollectionId, setActiveCollectionId] = useState(null);

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = async () => {
        try {
            const response = await axios.get(API_ENDPOINTS.getCollections);
            setCollections(response.data);
            const activeCollection = response.data.find(collection => collection.procollection_setactive === '1');
            if (activeCollection) {
                setActiveCollectionId(activeCollection.procollection_id);
            }
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleSetActive = async (collectionId) => {
        try {
            // Send request to backend to set this collection as active
            const response = await axios.post(API_ENDPOINTS.setActiveCollection(collectionId));

            // Log the response from the server
            console.log('Set active collection response:', response.data);

            // Optimistically update the UI
            setActiveCollectionId(collectionId);

            // Update the active status in the state
            setCollections(prevCollections =>
                prevCollections.map(collection => ({
                    ...collection,
                    procollection_setactive: collection.procollection_id === collectionId ? 1 : 0
                }))
            );

        } catch (error) {
            // Log detailed error information
            console.error('Error setting active collection:', error.response ? error.response.data : error.message);
        }
    };

    const handleDelete = async (collectionId) => {
        try {
            await axios.delete(`/api/collections/${collectionId}`); // Replace with your API endpoint
            fetchCollections(); // Refresh the collection list after deletion
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };

    const handleEdit = (collectionId) => {
        setCurrentCollectionId(collectionId);
        setCurrentView('createCollection');
    };

    return (
        <div className="collection-list-container">
            <h1>Collection List</h1>
            <button className="create-button" onClick={() => handleEdit(null)}>
                + Create New Collection
            </button>
            <table className="collection-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designer</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {collections.map(collection => (
                        <tr key={collection.procollection_id}>
                            <td>{collection.procollection_name}</td>
                            <td>{(collection.procollection_designer)}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={collection.procollection_id === activeCollectionId}
                                    onChange={() => handleSetActive(collection.procollection_id)}
                                />
                            </td>
                            <td>
                                <button
                                    className="edit-button"
                                    onClick={() => handleEdit(collection.procollection_id)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="delete-button"
                                    onClick={() => handleDelete(collection.procollection_id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CollectionList;
