import React, { useState, useEffect, useRef } from 'react';
import './Slideshow.css';

const slides = [
    {
        type: 'image',
        src: '/DALL·E GORGEOUS WOMEN.png',
        headline: 'Introducing Our New Collection',
        description: 'Discover the latest trends and timeless pieces from our new collection.',
    },
    {
        type: 'image',
        src: '/DALL·Ecollection.png',
        headline: 'Elegance Redefined',
        description: 'Experience elegance with our carefully curated selection of fashion-forward pieces.',
    },
    {
        type: 'video',
        src: '/Y2meta.app-Short Fashion Video.mp4', // Path to your video file
        headline: 'Crafted to Perfection',
        description: 'Watch how our new collection is made with the finest attention to detail.',
    },
];

const Slideshow = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const startX = useRef(0);
    const endX = useRef(0);

    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        endX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (startX.current > endX.current + 50) {
            goToNextSlide();
        } else if (startX.current < endX.current - 50) {
            goToPreviousSlide();
        }
    };

    const handleMouseDown = (e) => {
        startX.current = e.clientX;
    };

    const handleMouseUp = (e) => {
        endX.current = e.clientX;
        if (startX.current > endX.current + 50) {
            goToNextSlide();
        } else if (startX.current < endX.current - 50) {
            goToPreviousSlide();
        }
    };

    return (
        <div
            className="slideshow"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`slide ${index === currentSlide ? 'active' : ''}`}
                >
                    {slide.type === 'image' ? (
                        <img draggable='false' src={slide.src} alt={`Slide ${index}`} className="slide-image" />
                    ) : (
                        <video src={slide.src} className="slide-video" autoPlay muted loop />
                    )}
                    <div className="slide-content">
                        <h2>{slide.headline}</h2>
                        <p>{slide.description}</p>
                    </div>
                </div>
            ))}
            <div className="slideshow-dots">
                {slides.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => setCurrentSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slideshow;
