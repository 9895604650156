import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Ensure Link is imported
import './Showvideo.css';

const Showvideo = () => {
  const cardRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const cardElement = cardRef.current;
    const videoElement = videoRef.current;

    if (!cardElement || !videoElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cardElement.classList.add('visible');
            videoElement.play().catch((error) => {
              console.error('Video playback failed:', error);
            });
          } else {
            cardElement.classList.remove('visible');
            videoElement.pause();
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(cardElement);

    return () => {
      observer.unobserve(cardElement);
    };
  }, []);

  return (
    <div className="card" ref={cardRef}>
      <div className="card-content">
        <div className="video-container">
          <video
            ref={videoRef}
            width="480"
            height="720"
            autoPlay
            loop
            muted
            playsInline
            // Optionally add controls if needed
            // controls
          >
            <source
              src="https://testsever.gorgeouswomanth.com/gorgeousapi/public/uploads/videos/YANGOK.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-container">
          <h2>Wear Confidence, Be You</h2>
          <p>
            "แฟชั่นที่มากกว่าเสื้อผ้า... คือการสร้างความมั่นใจให้กับผู้หญิงทุกคน มั่นใจ สไตล์คุณ"
          </p>
          <Link to="/aboutus" className="link-tocollection">
            อ่านเพิ่มเติม
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Showvideo;