import React, { useState } from 'react';
import '../css/loginpage.css';
import '../css/body.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../connectapi';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === '' || password === '') {
      setError('โปรดกรอกชื่อผู้ใช้ และรหัสผ่าน');
      return;
    }

    try {
      // Send the login request with username and password in the request body
      const response = await axios.post(API_ENDPOINTS.login, {
        user_id: username,
        password: password,
      });

      if (response.status === 200) {
        // Successful login
        navigate('/dashboardgwpage');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('ชื่อผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง');
      } else {
        setError('เกิดข้อผิดพลาดในการเข้าสู่ระบบ');
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <h2>GORGEOUS WOMAN</h2>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="username">ไอดีผู้ใช้</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label htmlFor="password">รหัสผ่าน</label>
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="password-toggle-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
          </div>
          <button className="login_button" type="submit">เข้าสู่ระบบ</button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
