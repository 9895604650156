import React, { useState, useEffect } from 'react';
import '../css/list.css';
import '../css/body.css';
import Popup from '../responsivebar/Popup';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from '../responsivebar/Footer';
import ProductCard from './cards/product_card';
import axios from 'axios';
import { API_ENDPOINTS } from '../connectapi';
import { FaFilter, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ListPage = () => {
  const itemsPerPage = 12;

  const initialPage = parseInt(localStorage.getItem('currentPage')) || 1;
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [inputPage, setInputPage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isStyleOpen, setIsStyleOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('เรียงจาก...');
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [styles, setStyles] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const [isStyleAllChecked, setIsStyleAllChecked] = useState(true);
  const [isColorAllChecked, setIsColorAllChecked] = useState(true);
  const [isSizeAllChecked, setIsSizeAllChecked] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const [collectionName, setCollectionName] = useState('ผลิตภัณฑ์ใหม่');
  const [collectionImage, setCollectionImage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const collectionId = queryParams.get('collection_id');
    const typeId = queryParams.get('type_id');

    if (collectionId) {
      fetchCollectionData(collectionId);
    } else {
      setCollectionName('ผลิตภัณฑ์ใหม่');
      setCollectionImage('/DALL·Ewoman wearing a black tank top.png');
    }

    fetchInitialData(collectionId, typeId); // Pass both collectionId and typeId
  }, [location.search]);

  const fetchInitialData = async (collectionId, typeId) => {
    setLoading(true);
    try {
      const [productsRes, stylesRes, colorsRes, sizesRes] = await Promise.all([
        axios.get(API_ENDPOINTS.getlistProducts),
        axios.get(API_ENDPOINTS.getAllStyles),
        axios.get(API_ENDPOINTS.getColors),
        axios.get(API_ENDPOINTS.getAllSizes),
      ]);

      let fetchedProducts = productsRes.data;
      console.log('Fetched products:', fetchedProducts);
      console.log('Fetched styles:', stylesRes.data);
      console.log('Fetched colors:', colorsRes.data);
      console.log('Fetched sizes:', sizesRes.data);



      // Convert collectionId and typeId from URL to integers if they're provided
      const collectionIdAsInt = collectionId ? parseInt(collectionId, 10) : null;
      const typeIdAsInt = typeId ? parseInt(typeId, 10) : null;

      // Filter products by collection and/or type
      let filteredProducts = fetchedProducts.filter(product => {
        if (collectionIdAsInt) {
          return product.procollection_id === collectionId.toString();
        } else if (typeIdAsInt) {
          return product.variations.some(variation => variation.typeId === typeId.toString());
        } else {
          return true;
        }
      });

      setProducts(filteredProducts);
      setOriginalProducts(fetchedProducts);
      setStyles(stylesRes.data);
      setColors(colorsRes.data);
      setSizes(sizesRes.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setLoading(false);
    }
  };

  const filterProducts = () => {
    let filteredProducts = originalProducts;

    const queryParams = new URLSearchParams(location.search);
    const collectionId = queryParams.get('collection_id');

    if (collectionId) {
      filteredProducts = filteredProducts.filter(product =>
        product.procollection_id === collectionId
      );
    }

    if (searchTerm) {
      filteredProducts = filteredProducts.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedStyles.length > 0) {
      filteredProducts = filteredProducts.filter(product =>
        selectedStyles.includes(product.prosty_id)
      );
    }

    if (selectedColors.length > 0) {
      filteredProducts = filteredProducts.filter(product =>
        product.variations.some(variation => selectedColors.includes(variation.colorId))
      );
    }

    if (selectedSizes.length > 0) {
      filteredProducts = filteredProducts.filter(product =>
        product.variations.some(variation => selectedSizes.includes(variation.sizeId))
      );
    }

    setProducts(filteredProducts);
  };

  useEffect(() => {
    if (originalProducts.length > 0) {
      filterProducts();
    }
  }, [searchTerm, selectedStyles, selectedColors, selectedSizes]);

  const fetchCollectionData = async (collectionId) => {
    try {
      const response = await axios.get(API_ENDPOINTS.getCollectionById(collectionId));
      const collectionData = response.data;
      console.log(response);
      if (collectionData) {
        setCollectionName(collectionData.procollection_name);

        const formattedImagePath = collectionData.procollection_img
          ? collectionData.procollection_img
          : 'public-image.png';

        setCollectionImage(`${API_ENDPOINTS.getAPI}${formattedImagePath}`);
      }
    } catch (error) {
      console.error('Error fetching collection data:', error);
      setCollectionName('Public Collection');
      setCollectionImage('public-image.png');
    }
  };
  const handleClickOutside = (event) => {
    const filterDrawer = document.querySelector('.filter-drawer');
    const backdrop = document.querySelector('.backdrop');
    if (
      isFilterOpen &&
      filterDrawer &&
      !filterDrawer.contains(event.target) &&
      !backdrop.contains(event.target)
    ) {
      setIsFilterOpen(false); // Close filter if clicked outside
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFilterOpen]);

  const handleColorChange = (colorId) => {
    const updatedColors = selectedColors.includes(colorId)
      ? selectedColors.filter((id) => id !== colorId)
      : [...selectedColors, colorId];

    setSelectedColors(updatedColors);
    setIsColorAllChecked(updatedColors.length === 0);
  };

  const handleSizeChange = (sizeId) => {
    const updatedSizes = selectedSizes.includes(sizeId)
      ? selectedSizes.filter((id) => id !== sizeId)
      : [...selectedSizes, sizeId];

    setSelectedSizes(updatedSizes);
    setIsSizeAllChecked(updatedSizes.length === 0);
  };

  const handleStyleChange = (styleId) => {
    const updatedStyles = selectedStyles.includes(styleId)
      ? selectedStyles.filter((id) => id !== styleId)
      : [...selectedStyles, styleId];

    setSelectedStyles(updatedStyles);
    setIsStyleAllChecked(updatedStyles.length === 0);
  };

  const handleClearAllStyles = () => {
    setSelectedStyles([]);
    setIsStyleAllChecked(true);
  };

  const handleClearAllColors = () => {
    setSelectedColors([]);
    setIsColorAllChecked(true);
  };

  const handleClearAllSizes = () => {
    setSelectedSizes([]);
    setIsSizeAllChecked(true);
  };

  const handleSortOptionClick = (option) => {
    setSelectedSortOption(option);

    if (option === 'ใหม่') {
      const queryParams = new URLSearchParams(location.search);
      const collectionId = queryParams.get('collection_id');

      if (collectionId) {
        fetchInitialData(collectionId);
      } else {
        fetchInitialData();
      }
    } else {
      let sortedProducts = [...products];
      switch (option) {
        case 'ราคา น้อยไปมาก':
          sortedProducts.sort((a, b) => a.variations[0]?.price - b.variations[0]?.price);
          break;
        case 'ราคา มากไปน้อย':
          sortedProducts.sort((a, b) => b.variations[0]?.price - a.variations[0]?.price);
          break;
        default:
          break;
      }
      setProducts(sortedProducts);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const totalPages = Math.ceil(products.length / itemsPerPage);
  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageInput = (event) => {
    setInputPage(event.target.value);
  };

  const handlePageJump = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    } else {
      setShowPopup(true);
    }
    setInputPage('');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleFilterDrawer = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const filterElement = document.querySelector('.filter-bar');
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (window.innerWidth > 768) {  // Only apply sticky behavior on larger screens
        if (scrollTop >= filterElement.offsetTop) {
          setIsFilterSticky(true);
        } else {
          setIsFilterSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='body-container'>
      {/*
      <section className="banner-section">
        <img src={collectionImage} alt={collectionName} className="banner-image" />
        <div className="banner-overlay">
        <h1>{collectionName}</h1>
      </div>
      </section>
      */}


      <div className="filter-bar-placeholder"></div>
      <div className={`filter-bar ${isFilterSticky ? 'filter-sticky' : ''}`}>
        <div className="search-filter-container">

          <input
            type="text"
            placeholder="ค้นหาจากชื่อ..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <button className="filter-button" onClick={toggleFilterDrawer}>
            Filter & Sort
          </button>
        </div>
      </div>
      <h1 className='name-header'>{collectionName}</h1>
      <div className='filter-options-selected-container'>
        <div className="filter-options-selected">
          {selectedStyles.map(styleId => (
            <span key={styleId} className="filter-option-selected">
              {styles.find(style => style.prosty_id === styleId).prosty_name}
              <button onClick={() => handleStyleChange(styleId)}>×</button>
            </span>
          ))}
          {selectedColors.map(colorId => (
            <span key={colorId} className="filter-option-selected">
              {colors.find(color => color.procol_id === colorId).procol_name}
              <button onClick={() => handleColorChange(colorId)}>×</button>
            </span>
          ))}
          {selectedSizes.map(sizeId => (
            <span key={sizeId} className="filter-option-selected">
              {sizes.find(size => size.prosize_id === sizeId).prosize_name}
              <button onClick={() => handleSizeChange(sizeId)}>×</button>
            </span>
          ))}
        </div>
      </div>
      <div className="list-page">
        <main className="product-list">
          {currentProducts.map((product, index) => (
            <div key={product.pro_id}>
              <Link to={`/productDetail/${product.pro_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ProductCard
                  images={product.variations.flatMap(v => v.images)}
                  name={product.name}
                  price={product.variations[0]?.price || 0}
                  colors={[...new Set(product.variations.map(v => v.color))]}
                  loading={loading && index < 4}
                />
              </Link>
            </div>
          ))}
        </main>
        {isFilterOpen && <div className={`backdrop ${isFilterOpen ? 'open' : ''}`} onClick={toggleFilterDrawer}></div>}
        <div className={`filter-drawer ${isFilterOpen ? 'open' : ''}`}>
          <h2><FaFilter /> Filter</h2>
          <ul className="filter-options">
            <li onClick={toggleDropdown}>
              {selectedSortOption} <span className="arrow">{isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </li>
            {isDropdownOpen && (
              <ul className="collapsible-content">
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sortOption"
                      value="ใหม่"
                      checked={selectedSortOption === 'ใหม่'}
                      onChange={() => handleSortOptionClick('ใหม่')}
                    />
                    ใหม่
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sortOption"
                      value="ราคา น้อยไปมาก"
                      checked={selectedSortOption === 'ราคา น้อยไปมาก'}
                      onChange={() => handleSortOptionClick('ราคา น้อยไปมาก')}
                    />
                    ราคา น้อยไปมาก
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="sortOption"
                      value="ราคา มากไปน้อย"
                      checked={selectedSortOption === 'ราคา มากไปน้อย'}
                      onChange={() => handleSortOptionClick('ราคา มากไปน้อย')}
                    />
                    ราคา มากไปน้อย
                  </label>
                </li>
              </ul>
            )}
            <li onClick={() => setIsStyleOpen(!isStyleOpen)}>
              สไตล์ <span className="arrow">{isStyleOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </li>
            {isStyleOpen && (
              <>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isStyleAllChecked}
                      onClick={handleClearAllStyles}
                      readOnly
                    />
                    ทั้งหมด
                  </label>
                </li>
                {styles.map(style => (
                  <li key={style.prosty_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedStyles.includes(style.prosty_id)}
                        onChange={() => handleStyleChange(style.prosty_id)}
                      />
                      {style.prosty_name}
                    </label>
                  </li>
                ))}
              </>
            )}
            <li onClick={() => setIsColorOpen(!isColorOpen)}>
              สี <span className="arrow">{isColorOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </li>
            {isColorOpen && (
              <>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isColorAllChecked}
                      onClick={handleClearAllColors}
                      readOnly
                    />
                    ทั้งหมด
                  </label>
                </li>
                {colors.map(color => (
                  <li key={color.procol_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedColors.includes(color.procol_id)}
                        onChange={() => handleColorChange(color.procol_id)}
                      />
                      {color.procol_name}
                    </label>
                  </li>
                ))}
              </>
            )}
            <li onClick={() => setIsSizeOpen(!isSizeOpen)}>
              ขนาด <span className="arrow">{isSizeOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
            </li>
            {isSizeOpen && (
              <>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      checked={isSizeAllChecked}
                      onClick={handleClearAllSizes}
                      readOnly
                    />
                    ทั้งหมด
                  </label>
                </li>
                {sizes.map(size => (
                  <li key={size.prosize_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedSizes.includes(size.prosize_id)}
                        onChange={() => handleSizeChange(size.prosize_id)}
                      />
                      {size.prosize_name}
                    </label>
                  </li>
                ))}
              </>
            )}
          </ul>
          <button className="done-button" onClick={toggleFilterDrawer}>เลือก</button>
        </div>
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          &lt;
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index + 1}
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </span>
        ))}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          &gt;
        </button>
        <input
          type="text"
          value={inputPage}
          onChange={handlePageInput}
          placeholder="..."
        />
        <button onClick={handlePageJump}>Go</button>
      </div>
      {showPopup && <Popup message="Can't go to that page" onClose={() => setShowPopup(false)} />}
      <Footer />
    </div>
  );
};

export default ListPage;
