import '../css/layout.css';
import '../css/body.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../responsivebar/navbar';

function Layout() {
    document.addEventListener("DOMContentLoaded", () => {
        function wrapTextNodes(node) {
          if (node.nodeType === Node.TEXT_NODE) {
            const text = node.nodeValue;
            const parent = node.parentNode;
      
            // Split text into words
            const words = text.split(/(\s+)/);
      
            words.forEach((word) => {
              if (word.trim().length > 0) {
                const span = document.createElement("span");
                span.textContent = word;
      
                // Detect language and set lang attribute
                if (/[\u0E00-\u0E7F]/.test(word)) {
                  span.setAttribute("lang", "th");
                } else {
                  span.setAttribute("lang", "en");
                }
      
                parent.insertBefore(span, node);
              } else {
                parent.insertBefore(document.createTextNode(word), node);
              }
            });
      
            parent.removeChild(node);
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            node.childNodes.forEach(wrapTextNodes);
          }
        }
      
        wrapTextNodes(document.body);
      });
    return (
        <div className="App">
            <Navbar />
            <Outlet />
        </div>
    );
}
export default Layout;
